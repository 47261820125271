import React, { useState } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { Layout, Block, CategoryFilter } from '../components'
import moment from 'moment'

import { container, padding, bgImage, wrapper } from '../styles/global'
import { lightGrey } from '../styles/colors';
import { media, useBreakpoint, isClient } from '../styles/utils';

const Article = ({pageContext, ...props}) => {
	const { title, slug, acf, cats, service, serviceList, publishedDateUnix } = pageContext;
	const isPhone = useBreakpoint('phone');

	const navigateTo = (slug) => {
		if (service) {
			navigate(`/services/${slug}`)
		} else {
			navigate(`/journal${slug ?`?category=${slug}` : ''}`)
		}
	}

	const renderHeroImage = () => {
		if (!acf.hero_image) return;

		return (
			<Block
				layout={'image'}
				articleBlock={true}
				image={acf.hero_image}
				styles={css`
					margin-bottom: 30px;
				`}
			/>
		)
	}

	const renderIntro = () => {
        if (!acf.introduction) return;
        
		return (
			<Introduction>
				{!service && (
					<Date>
                        {moment.unix(publishedDateUnix).format('MM.DD.YYYY')}
					</Date>
				)}
				
				<Block
					layout={'text'}
					articleBlock={true}
					content={acf.introduction}
				/>
			</Introduction>
		)
	}

    const renderBlocks = (blocks) => {
		if (!blocks) return;

		return blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					articleBlock={true}
					{...block}
				/>
			)
		})
	}

	const renderShare = () => {
		if (service || !isClient()) return;

		const shareLinks = [
			{
				link: `https://twitter.com/share?url=${window.location.href}`,
				label: 'Tweet',
			},
			{
				link: `https://pinterest.com/pin/create/bookmarklet?url=${window.location.href}`,
				label: 'Pin',
			},
			{
				link: `mailto:?body=${window.location.href}`,
				label: 'Email',
			},
		]
		
		const items = shareLinks.map((item, i) => {
			return (
				<>
					<ShareLink 
						key={i}
						target={'_blank'}
						href={item.link}
					>
						{item.label}
					</ShareLink>

					{i !== shareLinks.length - 1 && (
						<Divider>|</Divider>
					)}
				</>
			)
		})

		return (
			<Share>
				<Heading>Share</Heading>
				{items}
			</Share>
		)
	}
	
	return (
		<Layout
			meta={pageContext && pageContext.acf.seo}
		>
			<Wrapper>	
				<Container>
					<Sidebar>
						<Heading>{title}</Heading>
						
						{isPhone && !service && cats && cats.length && (
							<Subheading
								onClick={() => navigateTo(cats[0].slig)}
							>
								{cats[0].name}
							</Subheading>
						)}

						<Filter
							items={service && serviceList}
							activeCategory={service ? slug : cats && cats.length && cats[0] && cats[0].slug}
							onChange={(cat) => navigateTo(cat)}
							hideAll={service}
						/>
				
					</Sidebar>
					<Content>
						{renderHeroImage()}
						{renderIntro()}
						{renderBlocks(acf.blocks)}
						{renderShare()}
					</Content>
				</Container>
			</Wrapper>
		</Layout>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${wrapper}
    padding-bottom: 160px;

	${media.phone`
		padding-bottom: 120px;
	`}
`

const Container = styled.div`
    ${container}
    ${padding}
	justify-content: space-between;

	${media.phone`
		flex-direction: column;
	`}
`

// Sidebar

const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
	margin-right: 96px;

	${Heading} {
		font-size: 36px;
		margin-bottom: 37px;
	}

	${Subheading} {
		color: ${lightGrey}
		font-size: 18px;
		margin-top: 10px;
	}
	
	${media.phone`
		margin-right: 0;

		${Heading} {
			margin-bottom: 0;
		}
	`}

	${media.tablet`
		margin-right: 24px;
	`}
`

const Filter = styled(CategoryFilter)`
	${media.phone`
		display: none;
	`}
`

// Content

const Content = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 827px;
	flex: 1 0 827px;

	${media.phone`
		flex: 0 1 auto;
		margin-top: 44px;
	`}
`

// Introduction

const Date = styled.div``

const Introduction = styled.div`
	position: relative;

	${Date} {
		position: absolute;
		top: 0;
		left: 0;

		font-size: 18px;
		flex: 0 1 73%;
		color: ${lightGrey};
	}

	${media.phone`
		${Date} {
			position: inherit;
			flex: 0 1 auto;	
			font-size: 14px;
			margin-bottom: 24px;
		}
	`}
`

// Share

const ShareLink = styled.a``
const Divider = styled.div``

const Share = styled.div`
	display: flex;
	margin-top: 80px;

	${Heading} {
		margin-right: 28px;
		color: rgba(0, 0, 0, 0.8);
	}

	${ShareLink} {
		color: ${lightGrey}
	}

	${Divider} {
		margin: 0 7px;
		color: ${lightGrey}
	}

	> * {
		font-size: 18px;
	}

	${media.phone`
		margin-top: 40px;

		> * {
			font-size: 14px;
		}
	`}
`

export default Article
